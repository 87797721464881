<template>
<div id="navBar">
  <div class="navContent">
    <router-link to="/"><img v-if="toggleDiv" class="main_logo" alt="Vue logo" :src="`https://www.cpanel-pumpview.com.ng/logoImage/${settings['logo_pic']}`"></router-link>
    <ul>
      <li><router-link to="/mapview">Map View</router-link></li>
      <li><router-link to="/facilities">List View</router-link></li>
      <li style="padding-right:0;"><router-link to="/about">About</router-link></li>
    </ul>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            settings: [],
            toggleDiv:false,
        };
    },
    methods: {
        run() {
            console.log(this.settings)
        }
    },
    beforeCreate() {
    axios
      .get('https://www.cpanel-pumpview.com.ng/api/v-1/get-settings')
      .then(response => {
        this.settings = response.data
        this.toggleDiv = true
      })
  },
};
</script>

<style scoped>
#navBar {
  background: #ffffff;
  box-shadow: 0px 0px 6px 0 #0000004f;
  margin-bottom: 3px;
}

.main_logo {
  width: 70px;
}

a {
  text-decoration: none;
  font-family: system-ui;
  color: #196BA3;
  font-weight: 500;
  font-size: 21px;
}

ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* max-width: 260px; */
  /* width: 100%; */
}

li {
  list-style: none;
  padding-right: 20px;
}

.navContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
  padding: 0 20px;
}

@media screen and (max-width: 850px) {
  .main_logo {
    width: 65px;
  }

  /* ul {
    max-width: 235px;
  } */

  a {
    font-size: 19px;
  }
}
@media screen and (max-width: 650px) {
  .main_logo {
    width: 55px;
  }

  /* ul {
    max-width: 160px;
  } */

  a {
    font-size: 18px;
  }
}
@media screen and (max-width: 550px) {
  .main_logo {
    width: 55px;
  }

  /* ul {
    max-width: 150px;
  } */

  a {
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {
  .main_logo {
    width: 55px;
  }

  li{
    padding-right: 10px;
  }
.navContent {
  padding: 0 7px;
}
  a {
    font-size: 15px;
  }
}
</style>
