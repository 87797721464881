<template>
    <div id="demo" v-if="!toggleDiv"></div>
    <div v-if="toggleDiv">
        <Home1 :data="homeData" />
        <TheProblem :data="homeData" />
        <TheSolution :data="homeData" />
        <OurMission :data="homeData" />
    </div>
    <TopFooter/>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Home1 from '@/components/Home1.vue'
import TheProblem from '@/components/TheProblem.vue'
import TheSolution from '@/components/TheSolution.vue'
import OurMission from '@/components/OurMission.vue'
import TopFooter from '@/components/TopFooter.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'

export default {
    name: 'HomeView',
    components: {
        Home1,
        TheProblem,
        TheSolution,
        OurMission,
        TopFooter,
        Footer
    },
    data() {
        return {
            homeData: [],
            toggleDiv: false,
        };
    },
    mounted() {
        axios
            .get('https://www.cpanel-pumpview.com.ng/api/v-1/get-home')
            .then(response => {
                this.homeData = response.data
                this.toggleDiv = true
            })
    }
}
</script>

<style scoped>
div#demo {
    min-height: 100vh;
}
</style>
