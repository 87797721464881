<template>
<div id="footer">
  <div class="footerContent">
    <img class="footer_logo" alt="Vue logo" src="./../assets/footer_logo.png">
    <div class="footer_element">
      <ul>
        <li><router-link to="/mapview">Map View</router-link><span class="border_line">|</span></li>
        <li><router-link to="/about-us">About Us</router-link><span class="border_line">|</span></li>
        <li><router-link to="/privacy&policy">Privacy & Policy</router-link><span class="border_line">|</span></li>
        <li><router-link to="/terms-of-use">Terms of Use</router-link></li>
      </ul>
      <div class="footer_text"><span>© Copyright 2022 PumpView</span></div>
    </div>
  </div>
</div>
</template>

<style scoped>
#footer {
  background: #071631;
}

.footer_logo {
  width: 120px;
}

.footerContent {
  max-width: 1300px;
  margin: auto;
  padding: 25px 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_element {
  font-size: 17px;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #ffffff;
}

span {
  color: #bd7cff;
}

span.border_line {
  margin: 0 10px;
  color: #979797;
}

ul {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

@media screen and (max-width: 650px) {
  .footerContent {
    flex-flow: column;
  }

  .footer_logo {
    width: 90px;
  }

  .footer_logo {
    margin: 0 auto 10px auto;
  }

  .footer_text {
    display: flex;
    justify-content: center;
  }

  ul {
    justify-content: center;
  }
}

@media screen and (max-width: 470px) {
  ul {
    flex-flow: wrap;
    justify-content: center;
  }
}
</style>
