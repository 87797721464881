import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import axios from 'axios'
import VueAxios from 'vue-axios'

createApp(App).use(VueGoogleMaps, {
  load: {
      key: 'AIzaSyAVKjCxMvk5Nymx6VYSlhc4iOasFoTxuCk',
  },
}).use(VueAxios, axios).use(router).mount('#app')
