<template>
<div id="theSolution">
  <div class="theSolutionContent">
    <div class="right_row">
      <div class="top_box"></div>
      <img class="bg_2" :src="`https://www.cpanel-pumpview.com.ng/homeImage/${data['section3_image']}`" alt="" srcset="">
      <div class="bottom_box"></div>
    </div>
    <div class="left_row">
      <h2>{{ data.section3_heading }}</h2>
      <p>{{ data.section3_text }}</p><button><router-link to="/mapview">Locate Now</router-link></button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Home1",
  props:{
    data:{type:Object}
  },
}
</script>

<style scoped>
div#theSolution {
  padding: 40px 0;
  background: #ffffff;
  /* height: 600px; */
}

.theSolutionContent {
  max-width: 1300px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
button {
    padding: 15px 40px;
    border-radius: 5px;
    border: none;
    background: #19679C;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 15px;
    color: #ffffff;
}
/* .bg2_wrapper {
    width: 500px;
    height: 500px;
    background: #F0F3F7;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    margin: 20px;
    position: relative;
} */
img.bg_2 {
  display: block;
  padding: 20px;
  position: relative;
  z-index: 2;
  width: 100%;
}

p {
  font-size: 18px;
  max-width: 500px;
  line-height: 30px;
  margin-top: 10px;
}

h2 {
  font-size: 32px;
  color: #002568;
}

.top_box,
.bottom_box {
  width: 200px;
  height: 200px;
  background: #7BC142;
  z-index: 1;
  border-radius: 5px;
}

.right_row {
  position: relative;
}

.left_row {
  margin-left: 50px;
}

.top_box {
  position: absolute;
  top: 0;
  right: 0;
}

.bottom_box {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 1000px) {

  .top_box,
  .bottom_box {
    width: 150px;
    height: 150px;
  }

  img.bg_2 {
    padding: 15px;
  }
}

@media screen and (max-width: 800px) {

  .top_box,
  .bottom_box {
    width: 120px;
    height: 120px;
  }

  img.bg_2 {
    padding: 13px;
  }

  p {
    font-size: 14px;
    line-height: 25px;
  }
}

@media screen and (max-width: 700px) {
  .theSolutionContent {
    flex-flow: column;
  }

  .left_row {
    margin-left: 0;
    text-align: center;
    margin-top: 20px;
  }

  .top_box,
  .bottom_box {
    width: 180px;
    height: 180px;
  }
}

@media screen and (max-width: 450px) {

  .top_box,
  .bottom_box {
    width: 120px;
    height: 120px;
  }

  img.bg_2 {
    padding: 8px;
  }
}
a{
  text-decoration: none;
  color: rgb(255, 255, 255);
}
</style>
