<template>
  <div id="topFooter">
    <div class="topFooterWrapper">
      <div class="topFooterContent">
      <div class="left_row"><b>Need any Assistance?</b><span class="top_footer_span">
Our team is available to help you!</span></div>
      <div class="right_row"><a href="https://www.greenhabitat.ng/contact/" target="_blank"><button>Contact us</button></a></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#topFooter{
  background: #ffffff;
  padding: 40px 0;
  color: #ffffff;
}
.topFooterWrapper{
  max-width: 1300px;
  padding: 0 20px;
  margin: auto;
}
.topFooterContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 100px;
    background: #19679C;
    border-radius: 10px;
}
button {
    padding: 10px 30px;
    border-radius: 5px;
    border: none;
    background: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 5px;
    color: #19679C;
}
.left_row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: column;
}

b {
    margin-bottom: 5px;
}
@media screen and (max-width: 700px) {
  .topFooterContent {
    padding: 40px 66px;
  }
}
@media screen and (max-width: 600px) {
  .topFooterContent {
    padding: 30px 50px;
  }
}
@media screen and (max-width: 550px) {
  .topFooterContent {
    padding: 20px 25px;
  }
}
@media screen and (max-width: 500px) {
  .topFooterContent {
    padding: 20px 25px;
    flex-flow: column;
  }
  .left_row{
    align-items: center;
  }
  button{
    margin-top: 20px;
  }
  .top_footer_span{
    text-align: center;
    margin-bottom: 7px;
  }
}
</style>