<template>
  <div id="home1">
    <div class="home1Content">
      <div class="side_bg_wrapper"><img class="side_bg" :src="`https://www.cpanel-pumpview.com.ng/homeImage/${data['section1_image']}`" alt="" srcset=""></div>
      <div class="text"><h2>{{ data.section1_heading }}</h2>
      <button><router-link to="/mapview">Locate Now</router-link></button></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home1",
  props:{
    data:{type:Object}
  },
}
</script>
<style scoped>
#home1{
  padding: 40px 0;
  background: #F9FFF4;
  /* height: 600px; */
}
.home1Content{
  position: relative;
  max-width: 1260px;
  margin: auto;
  border-radius: 15px;
  height: 100%;
  /* margin-top: 40px; */
}
.side_bg_wrapper{
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
}
.side_bg{
  width: 100%;
  display: block;
  border-radius: 15px;
  /* height: 100%; */
}

.text{
  border-radius: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background: #0000006b;
}
h2 {
    font-size: 52px;
    color: #ffffff;
    max-width: 950px;
}
button {
    padding: 10px 30px;
    border-radius: 5px;
    border: none;
    background: #7BC142;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 5px;
}
@media screen and (max-width: 950px) {
.side_bg{
  height: 100%;
  width: unset;
}
}
@media screen and (max-width: 870px) {
  h2 {
    font-size: 42px;
    max-width: 750px;
}
}
@media screen and (max-width: 700px) {
  h2 {
    font-size: 32px;
    max-width: 600px;
}
.home1Content, .side_bg, .text{
  border-radius: 5px;
}
}
@media screen and (max-width: 620px) {
  h2 {
    font-size: 25px;
    max-width: 500px;
}
}
@media screen and (max-width: 520px) {
  h2 {
    font-size: 25px;
    max-width: 450px;
}
button {
  padding: 7px 15px;
  font-size: 12px;
}
}
@media screen and (max-width: 450px) {
  h2 {
    font-size: 22px;
    max-width: 450px;
}
}
@media screen and (max-width: 370px) {
  h2 {
    font-size: 20px;
    max-width: 450px;
}
}

a{
  text-decoration: none;
  color: black;
}
</style>